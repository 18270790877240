import "@picocss/pico"
import image from "./asset/smile.png"
import Alpine from 'alpinejs'


window.Alpine = Alpine

Alpine.start()
console.log(image);
document.getElementById("logo").src = image

async function getAsyncJoke() {
    const respon = await fetch("https://icanhazdadjoke.com/", { method: "GET", headers: { 'Accept': 'application/json' } })
    const joke = await respon.json()
    document.getElementById("joke").innerText = joke.joke
}

function getJoke() {
    fetch("https://icanhazdadjoke.com/", { method: "GET", headers: { 'Accept': 'application/json' } }).then(respon => respon.json()).then(joke => document.getElementById("joke").innerText = joke.joke);
}
getAsyncJoke()
window.getAsyncJoke = getAsyncJoke
window.getJoke = getJoke